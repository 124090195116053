import { Fragment } from 'react';
import { Stack, Typography } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';
import MuiRadioGroupSub from './components/MuiRadioGroupSub';
import MuiRadioOUTPLAN from './components/MuiRadioOUTPLAN';

import RowCheckboxOutPlan from './components/RowCheckboxOutPlan';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentSub';
import SectionTitle from '../components/SectionTitle';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const OutHospitalPlan = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="교육 요구도" />
      <RowContainer xs={12}>
        <RowContent title="필요 교육 항목">
          <Stack direction="row" spacing={1}>
            <RowCheckboxOutPlan
              i18nNullKey="ETC"
              i18nKey="ASAN.HOSPITALIZATION.STUDY"
              values={[1, 2, 3, 4, 5, 6]}
              disabled={disabled}
              defaultValue={getValues(
                'amis_educational_demand.educational_demand_requirement_check.checked'
              )}
              onChange={v =>
                setValue(
                  'amis_educational_demand.educational_demand_requirement_check.checked',
                  v
                )
              }
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
            <RowCheckboxOutPlan
              i18nNullKey="ETC"
              i18nKey="ASAN.HOSPITALIZATION.STUDY"
              values={[0]}
              disabled={disabled}
              defaultValue={getValues(
                'amis_educational_demand.educational_demand_requirement_check.checked'
              )}
              onChange={v =>
                setValue(
                  'amis_educational_demand.educational_demand_requirement_check.checked',
                  v
                )
              }
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ maxWidth: '150px' }}
              {...register(
                'amis_educational_demand.educational_demand_requirement_check.input'
              )}
            />
          </Stack>
        </RowContent>

        <RowContent title="선호 교육 방법">
          <Stack direction="row" spacing={1}>
            <RowCheckboxOutPlan
              i18nNullKey="ETC"
              i18nKey="ASAN.HOSPITALIZATION.STUDYONE"
              values={[1, 2, 3, 4, 5]}
              disabled={disabled}
              defaultValue={getValues(
                'amis_educational_demand.educational_demand_prefer_way_check.checked'
              )}
              onChange={v =>
                setValue(
                  'amis_educational_demand.educational_demand_prefer_way_check.checked',
                  v
                )
              }
            />
          </Stack>
        </RowContent>

        <RowContent title="선호 교육">
          <Stack direction="row" spacing={1}>
            <RowCheckboxOutPlan
              i18nNullKey="ETC"
              i18nKey="ASAN.HOSPITALIZATION.STUDYTWO"
              values={[1, 2, 3]}
              disabled={disabled}
              defaultValue={getValues(
                'amis_educational_demand.educational_demand_prefer_scale_check.checked'
              )}
              onChange={v =>
                setValue(
                  'amis_educational_demand.educational_demand_prefer_scale_check.checked',
                  v
                )
              }
            />
          </Stack>
        </RowContent>
      </RowContainer>

      <SectionTitle title="퇴원 계획" />
      <RowContainer xs={12}>
        <RowContent title="필요 교육 항목">
          <Stack direction="row" spacing={1}>
            <MuiRadioGroupSub
              i18nKey="EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('discharge_plan_ox')}
              onChange={v => setValue('discharge_plan_ox', v)}
              width="125px"
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
            <Typography
              sx={{
                width: '130px',
                fontSize: '14px',
                paddingTop: '8px',
              }}
            >
              퇴원 후 돌봄 제공자
            </Typography>
            <MuiRadioOUTPLAN
              i18nKey="ASAN.HOSPITALIZATION.OUTPLAN"
              values={[1, 2, 3, 4, 5, 6, 7, 8, 0]}
              disabled={disabled}
              defaultValue={getValues('discharge_plan_teacher_check')}
              onChange={v => setValue('discharge_plan_teacher_check', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접입력"
              sx={{ maxWidth: '120px' }}
              {...register('discharge_plan_teacher_input')}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default OutHospitalPlan;
