import { Fragment } from 'react';
import { Stack } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentSub';
import SectionTitle from '../components/SectionTitle';
import MuiRadioGroupSub from './components/MuiRadioGroupSub';
import CheckoutGroupSub from './components/ColumnCheckboxGroup';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const SocialStatus = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="사회적 상태" />

      <RowContainer xs={12}>
        <RowContent title="결혼여부">
          <MuiRadioGroupSub
            i18nKey="ASAN.HOSPITALIZATION.SOCIALSTATUS"
            values={[1, 2, 0]}
            disabled={disabled}
            defaultValue={getValues('marriage_check')}
            onChange={v => setValue('marriage_check', v)}
          />
          <Form.MuiTextField
            required={false}
            disabled={disabled}
            placeholder="직접 입력"
            sx={{ width: '150px' }}
            {...register('marriage_input')}
          />
        </RowContent>
        <RowContent title="직업">
          <Stack direction="row" spacing={1}>
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              sx={{ width: '100%' }}
              placeholder="직접 입력"
              {...register('occupation')}
            />
          </Stack>
        </RowContent>
        <RowContent title="종교">
          <Stack direction="row" spacing={1}>
            <MuiRadioGroupSub
              i18nKey="ASAN.HOSPITALIZATION.RELIGION"
              values={[1, 2, 3, 4, 0]}
              disabled={disabled}
              defaultValue={getValues('religion_check')}
              onChange={v => setValue('religion_check', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              sx={{ width: '150px' }}
              placeholder="직접 입력"
              {...register('religion_input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="교육정도">
          <Stack direction="row" spacing={1}>
            <MuiRadioGroupSub
              i18nKey="ASAN.HOSPITALIZATION.EDUCATION"
              values={[1, 2, 3, 4, 5, 6, 7, 0]}
              disabled={disabled}
              defaultValue={getValues('disease_history.latest_medicine.value')}
              onChange={v =>
                setValue('disease_history.latest_medicine.value', v)
              }
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              sx={{ width: '150px' }}
              placeholder="직접 입력"
              {...register('disease_history.latest_medicine.input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="사용가능언어">
          <div style={{ display: 'flex' }}>
            <CheckoutGroupSub
              i18nNullKey="ETC"
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.LANGUAGE"
              values={[1, 2, 3, 4, 5, 6, 0]}
              defaultValue={getValues('available_language.languages.checked')}
              onChange={v =>
                setValue('available_language.languages.checked', v)
              }
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              sx={{ width: '150px', marginTop: '275px' }}
              placeholder="직접 입력"
              {...register('available_language.languages.input')}
            />
          </div>
        </RowContent>
        <RowContent title="문화적 특수성">
          <Stack direction="row" spacing={1}>
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              sx={{ width: '100%' }}
              placeholder="직접 입력"
              {...register('cultural_specialty')}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default SocialStatus;
