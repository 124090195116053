import { Fragment } from 'react';
import { Stack } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';
import SectionTitle from '../components/SectionTitle';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const BMI = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="영양 위험 검색" />

      <RowContainer xs={12}>
        <RowContent title="BMI">
          <Stack direction="row" spacing={1}>
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '150px' }}
              {...register('nutritional_danger_bmi_input')}
            />
            <Form.MuiRadioGroup
              i18nKey="ASAN.HOSPITALIZATION.bmi"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('nutritional_danger_bmi_check')}
              onChange={v => setValue('nutritional_danger_bmi_check', v)}
            />
          </Stack>
        </RowContent>
        <RowContent title="한 달간 의도치 않게 체중이 kg 이상 감소">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              i18nKey="YESORNO"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('nutritional_danger_check_monthly')}
              onChange={v => setValue('nutritional_danger_check_monthly', v)}
            />
          </Stack>
        </RowContent>
        <RowContent title="일주일간 식사량이 평소의 절반 이하 감소">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              i18nKey="YESORNO"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('nutritional_danger_check_weekly')}
              onChange={v => setValue('nutritional_danger_check_weekly', v)}
            />
          </Stack>
        </RowContent>
        <RowContent title="중환자실 입실">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              i18nKey="YESORNO"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('nutritional_danger_check_icu')}
              onChange={v => setValue('nutritional_danger_check_icu', v)}
            />
          </Stack>
        </RowContent>
        <RowContent title="영양 상태 평가 결과">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              i18nKey="ASAN.HOSPITALIZATION.BMI"
              values={[3, 4]}
              disabled={disabled}
              defaultValue={getValues('nutritional_danger_check_result')}
              onChange={v => setValue('nutritional_danger_check_result', v)}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default BMI;
