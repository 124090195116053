import { Fragment, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { AccessTime } from '@mui/icons-material';
import MuiTextField from 'components/Form/MuiTextField';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentSub';
import SectionTitle from '../components/SectionTitle';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const DefaultInfo = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;
  const [time, setTime] = useState(null);

  return (
    <Fragment>
      <SectionTitle title="기본 정보" />

      <RowContainer xs={12}>
        <RowContent title="발병일자">
          <Form.MuiTextField
            type="date"
            disabled={disabled}
            fullWidth={false}
            required={false}
            {...register('outbreak_date')}
          />
          <MobileTimePicker
            value={time}
            onChange={setTime}
            renderInput={params => (
              <MuiTextField
                {...params}
                required={false}
                sx={{ width: '210px', marginLeft: '20px' }}
                placeholder="00:00 pm"
                InputProps={{ endAdornment: <AccessTime /> }}
                {...register('outbreak_time')}
              />
            )}
          />
        </RowContent>
        <RowContent title="정보제공자">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              disabled={disabled}
              i18nNullKey="ETC"
              i18nKey="ASAN.HOSPITALIZATION.PROVIDER"
              values={[1, 2, 3, 4, 5, 6, 7, 8, 0]}
              defaultValue={getValues('info_offer_check')}
              onChange={v => setValue('info_offer_check', v)}
            />
            <Form.MuiTextField
              required={false}
              fullWidth={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '200px' }}
              {...register('info_offer_input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="현주소">
          <Form.MuiTextField
            required={false}
            fullWidth={true}
            disabled={disabled}
            {...register('address')}
          />
        </RowContent>
        <RowContent title="전화번호">
          <div style={{ display: 'flex', width: '95%' }}>
            <div style={{ display: 'flex', marginRight: '20px' }}>
              <Typography
                sx={{
                  width: '110px',
                  fontSize: '14px',
                  paddingTop: '8px',
                }}
              >
                1. 주보호자
              </Typography>
              <Form.MuiTextField
                required={false}
                fullWidth={false}
                disabled={disabled}
                {...register('contacts_01_name')}
              />
            </div>
            <div style={{ display: 'flex', marginRight: '20px' }}>
              <Typography
                sx={{
                  width: '110px',
                  fontSize: '14px',
                  paddingTop: '8px',
                }}
              >
                관계
              </Typography>
              <Form.MuiTextField
                required={false}
                fullWidth={false}
                disabled={disabled}
                {...register('contacts_01_relationship')}
              />
            </div>

            <div style={{ display: 'flex', marginRight: '20px' }}>
              <Typography
                sx={{
                  width: '110px',
                  fontSize: '14px',
                  paddingTop: '8px',
                }}
              >
                2. 보호자
              </Typography>
              <Form.MuiTextField
                required={false}
                fullWidth={false}
                disabled={disabled}
                {...register('contacts_02_name')}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <Typography
                sx={{
                  width: '110px',
                  fontSize: '14px',
                  paddingTop: '8px',
                }}
              >
                관계
              </Typography>
              <Form.MuiTextField
                required={false}
                fullWidth={false}
                disabled={disabled}
                {...register('contacts_02_relationship')}
              />
            </div>
          </div>
        </RowContent>
        <RowContent title="가족력">
          <div style={{ display: 'flex', width: '95%', marginBottom: '10px' }}>
            <div style={{ display: 'flex' }}>
              <Typography
                sx={{
                  width: '100px',
                  fontSize: '14px',
                  paddingTop: '8px',
                }}
              >
                암 가족력
              </Typography>
              <Form.MuiRadioGroup
                disabled={disabled}
                i18nKey="EXIST"
                values={[1, 2]}
                defaultValue={getValues('family_history_cancer_check')}
                onChange={v => setValue('family_history_cancer_check', v)}
              />
              <Form.MuiTextField
                required={false}
                disabled={disabled}
                placeholder="ex. 부 - 폐암"
                sx={{ width: '1025px' }}
                {...register('family_history_cancer_input')}
              />
            </div>
          </div>

          <div style={{ display: 'flex', width: '95%' }}>
            <div style={{ display: 'flex' }}>
              <Typography
                sx={{
                  width: '100px',
                  fontSize: '14px',
                  paddingTop: '8px',
                }}
              >
                기타 가족력
              </Typography>
              <Form.MuiRadioGroup
                disabled={disabled}
                i18nKey="EXIST"
                values={[1, 2]}
                defaultValue={getValues('family_history_etc_check')}
                onChange={v => setValue('family_history_etc_check', v)}
              />
              <Form.MuiTextField
                required={false}
                disabled={disabled}
                placeholder="ex. 모 - 고혈압"
                sx={{ width: '1025px' }}
                {...register('family_history_etc_input')}
              />
            </div>
          </div>

          <div style={{ display: 'flex', width: '95%', marginTop: '10px' }}>
            <div style={{ display: 'flex' }}>
              <Form.MuiCheckboxGroup
                i18nNullKey="ETC"
                disabled={disabled}
                i18nKey="ASAN.HOSPITALIZATION.FAMILYHISTORY"
                values={[1]}
                defaultValue={getValues('relations.check')}
                onChange={v => setValue('relations.check', v)}
              />
              <Form.MuiTextField
                required={false}
                disabled={disabled}
                placeholder="직접 입력"
                sx={{ width: '1223px', marginLeft: '20px' }}
                {...register('relations.input')}
              />
            </div>
          </div>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default DefaultInfo;
