import { Fragment } from 'react';
import { Stack, Typography, TextField, Grid } from '@mui/material';

import Form from 'components/Form';

import CheckboxGroup from './components/RowCheckboxHabit';
import CheckboxHabitTwo from './components/CheckboxHabitTwo';

import MuiRadioHabit from './components/MuiRadioHabit';

import { TGender } from 'apis/account/type';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentSub';
import SectionTitle from '../components/SectionTitle';

interface Props extends IFormRegister, IFormValues {
  gender: TGender;
  disabled?: boolean;
}

const Habit = (props: Props) => {
  const { gender, disabled, register, getValues, setValue } = props;

  // gender 여성인 경우 필수
  const isRequiredObstetric = gender === 1;

  return (
    <Fragment>
      <SectionTitle title="습관" />
      <RowContainer xs={12}>
        <RowContent title="대변">
          <Stack direction="row" spacing={1}>
            <Typography
              sx={{
                width: '50px',
                fontSize: '14px',
                paddingTop: '8px',
              }}
            >
              횟수
            </Typography>
            <Form.MuiTextField
              type="number"
              textAlign="right"
              disabled={disabled}
              required={false}
              sx={{ maxWidth: '150px' }}
              InputProps={{ ...Form.adornment('', '회') }}
              {...register('habit_stool_times_per')}
            />
            <Form.MuiTextField
              type="number"
              textAlign="right"
              disabled={disabled}
              required={false}
              sx={{ maxWidth: '150px' }}
              InputProps={{ ...Form.adornment('', '일') }}
              {...register('habit_stool_day')}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
            <Typography
              sx={{
                width: '50px',
                fontSize: '14px',
                paddingTop: '8px',
              }}
            >
              양상
            </Typography>
            <CheckboxHabitTwo
              i18nNullKey="HABITSTTOL"
              i18nKey="ASAN.HOSPITALIZATION.HABITSTTOL"
              values={[1, 2, 3, 4, 5, 6, 7]}
              disabled={disabled}
              defaultValue={getValues('habit_stool_pattern_check.checked')}
              onChange={v => setValue('habit_stool_pattern_check.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접입력"
              sx={{ maxWidth: '140px' }}
              {...register('habit_stool_pattern_check.input1')}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
            <Typography
              sx={{
                width: '50px',
                fontSize: '14px',
                paddingTop: '8px',
              }}
            ></Typography>
            <CheckboxHabitTwo
              i18nNullKey="ETC"
              i18nKey="ASAN.HOSPITALIZATION.HABITSTTOL"
              values={[8, 0]}
              disabled={disabled}
              defaultValue={getValues('habit_stool_pattern_check.checked')}
              onChange={v => setValue('habit_stool_pattern_check.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접입력"
              sx={{ maxWidth: '140px' }}
              {...register('habit_stool_pattern_check.input2')}
            />
          </Stack>
        </RowContent>
        <RowContent title="소변">
          <Stack direction="row" spacing={1}>
            <Typography
              sx={{
                width: '50px',
                fontSize: '14px',
                paddingTop: '8px',
              }}
            >
              횟수
            </Typography>
            <Form.MuiTextField
              type="number"
              textAlign="right"
              disabled={disabled}
              required={false}
              sx={{ maxWidth: '150px' }}
              InputProps={{ ...Form.adornment('', '회') }}
              {...register('habit_urine_times_per')}
            />
            <Form.MuiTextField
              type="number"
              textAlign="right"
              disabled={disabled}
              required={false}
              sx={{ maxWidth: '150px' }}
              InputProps={{ ...Form.adornment('', '일') }}
              {...register('habit_urine_day')}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
            <Typography
              sx={{
                width: '50px',
                fontSize: '14px',
                paddingTop: '8px',
              }}
            >
              양상
            </Typography>
            <MuiRadioHabit
              i18nKey="ASAN.HOSPITALIZATION.URIN"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('habit_urine_pattern_check')}
              onChange={v => setValue('habit_urine_pattern_check', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접입력"
              sx={{ maxWidth: '140px' }}
              {...register('habit_urine_pattern_input')}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
            <Typography
              sx={{
                width: '65px',
                fontSize: '14px',
                paddingTop: '8px',
                marginRight: '30px',
                textAlign: 'right',
              }}
            >
              색깔
            </Typography>
            <CheckboxGroup
              i18nNullKey="ETC"
              i18nKey="ASAN.HOSPITALIZATION.URINONE"
              values={[1, 2, 3, 4, 5, 6]}
              disabled={disabled}
              defaultValue={getValues('habit_urine_color_check.checked')}
              onChange={v => setValue('habit_urine_color_check.checked', v)}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
            <Typography
              sx={{
                width: '65px',
                fontSize: '14px',
                paddingTop: '8px',
                marginRight: '30px',
                textAlign: 'right',
              }}
            ></Typography>
            <CheckboxGroup
              i18nNullKey="ETC"
              i18nKey="ASAN.HOSPITALIZATION.URINONE"
              values={[7, 0]}
              disabled={disabled}
              defaultValue={getValues('habit_urine_color_check.checked')}
              onChange={v => setValue('habit_urine_color_check.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접입력"
              sx={{ maxWidth: '140px' }}
              {...register('habit_urine_color_check.input')}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
            <Typography
              sx={{
                width: '65px',
                fontSize: '14px',
                paddingTop: '8px',
                marginRight: '30px',
                textAlign: 'right',
              }}
            >
              양
            </Typography>
            <Form.MuiTextField
              type="number"
              required={false}
              disabled={disabled}
              InputProps={{ ...Form.adornment('', 'cc') }}
              sx={{ maxWidth: '140px' }}
              {...register('habit_urine_amount_input')}
            />

            <Typography
              sx={{
                width: '73px',
                fontSize: '14px',
                paddingTop: '8px',
                marginRight: '30px',
                textAlign: 'right',
              }}
            >
              냄새
            </Typography>
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ maxWidth: '140px' }}
              {...register('habit_urine_odor')}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
            <Typography
              sx={{
                width: '65px',
                fontSize: '14px',
                paddingTop: '8px',
                marginRight: '30px',
                textAlign: 'right',
              }}
            ></Typography>
            <CheckboxGroup
              i18nNullKey="ETC"
              i18nKey="ASAN.HOSPITALIZATION.URINTWO"
              values={[1, 2, 3, 4, 5, 6]}
              disabled={disabled}
              defaultValue={getValues('habit_urine_check.checked')}
              onChange={v => setValue('habit_urine_check.checked', v)}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
            <Typography
              sx={{
                width: '65px',
                fontSize: '14px',
                paddingTop: '8px',
                marginRight: '30px',
                textAlign: 'right',
              }}
            ></Typography>
            <CheckboxGroup
              i18nNullKey="ETC"
              i18nKey="ASAN.HOSPITALIZATION.URINTWO"
              values={[7, 8, 9, 10, 0]}
              disabled={disabled}
              defaultValue={getValues('habit_urine_check.checked')}
              onChange={v => setValue('habit_urine_check.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ maxWidth: '110px' }}
              {...register('habit_urine_check.input')}
            />
          </Stack>
        </RowContent>

        <RowContent title="수면 상태">
          <Stack direction="row" spacing={1}>
            <Typography
              sx={{
                width: '65px',
                fontSize: '14px',
                paddingTop: '8px',
                marginRight: '30px',
                textAlign: 'right',
              }}
            >
              수면시간
            </Typography>
            <Form.MuiTextField
              type="number"
              required={false}
              disabled={disabled}
              InputProps={{ ...Form.adornment('', 'hr(s)') }}
              sx={{ maxWidth: '140px' }}
              {...register('habit_sleep_hours')}
            />

            <Typography
              sx={{
                width: '150px',
                fontSize: '14px',
                paddingTop: '8px',
                marginRight: '30px',
                paddingLeft: '20px',
              }}
            >
              수면 장애
            </Typography>
            <MuiRadioHabit
              i18nKey="EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('habit_sleep_check')}
              onChange={v => setValue('habit_sleep_check', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ maxWidth: '110px' }}
              {...register('habit_sleep_input')}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default Habit;
