import { Fragment } from 'react';
import { Stack, TextField, Typography } from '@mui/material';

import Form from 'components/Form';

import BodyConditionCheckRadio from './components/BodyConditionCheckRadio';
import RowCheckboxWidth from './components/RowCheckboxWidth';

import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentSub';
import SectionTitle from '../components/SectionTitle';
import MuiRadioWidth from './components/MuiRadioWidth';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const BodyStatus = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="신체적 상태" />

      <RowContainer xs={12}>
        <RowContent title="활력징후">
          <Stack direction="row" spacing={3}>
            <Form.MuiTextField
              type="number"
              required={false}
              disabled={disabled}
              InputProps={{ ...Form.adornment('sbp', 'mmHg') }}
              sx={{ width: '250px' }}
              {...register('sbp')}
            />
            <Form.MuiTextField
              type="number"
              required={false}
              disabled={disabled}
              InputProps={{ ...Form.adornment('dbp', 'mmHg') }}
              sx={{ width: '250px' }}
              {...register('dbp')}
            />
            <Form.MuiTextField
              type="number"
              required={false}
              disabled={disabled}
              InputProps={{ ...Form.adornment('pr', '회') }}
              sx={{ width: '170px' }}
              {...register('pr')}
            />
            <Form.MuiTextField
              type="number"
              required={false}
              disabled={disabled}
              InputProps={{ ...Form.adornment('rr', '회') }}
              sx={{ width: '170px' }}
              {...register('rr')}
            />
            <Form.MuiTextField
              type="number"
              required={false}
              disabled={disabled}
              InputProps={{ ...Form.adornment('bt', '°C') }}
              sx={{ width: '170px' }}
              {...register('bt')}
            />
            <Form.MuiTextField
              type="number"
              required={false}
              disabled={disabled}
              InputProps={{ ...Form.adornment('spO2', '%') }}
              sx={{ width: '170px' }}
              {...register('sp02')}
            />
          </Stack>
        </RowContent>
        <RowContent title="신체">
          <Stack direction="row" spacing={3} sx={{ marginBottom: '10px' }}>
            <Form.MuiTextField
              type="number"
              required={false}
              disabled={disabled}
              InputProps={{ ...Form.adornment('키', 'cm') }}
              sx={{ width: '250px' }}
              {...register('height')}
            />
            <Form.MuiTextField
              type="number"
              required={false}
              disabled={disabled}
              InputProps={{ ...Form.adornment('몸무게', 'kg') }}
              sx={{ width: '250px' }}
              {...register('weight')}
            />
          </Stack>
          <Form.MuiRadioGroup
            i18nKey="ASAN.HOSPITALIZATION.BADYCONDITION"
            values={[1, 2, 3]}
            disabled={disabled}
            defaultValue={getValues('measurement_method')}
            onChange={v => setValue('measurement_method', v)}
          />
        </RowContent>
        <RowContent title="호흡기계">
          <Form.MuiRadioGroup
            i18nKey="EXIST"
            values={[1, 2]}
            disabled={disabled}
            defaultValue={getValues('respiratory_ox')}
            onChange={v => setValue('respiratory_ox', v)}
          />
          <Stack direction="row" spacing={3} sx={{ marginTop: '10px' }}>
            <RowCheckboxWidth
              i18nNullKey="SPO"
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.RESPIRATORY"
              values={[1, 2, 3, 4, 5, 0]}
              defaultValue={getValues('respiratory_check.checked')}
              onChange={v => setValue('respiratory_check.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '150px' }}
              {...register('respiratory_check.input1')}
            />
          </Stack>
          <Stack direction="row" spacing={3} sx={{ marginTop: '10px' }}>
            <RowCheckboxWidth
              i18nNullKey="ETC"
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.RESPIRATORYETC"
              values={[0]}
              defaultValue={getValues('respiratory_check.checked')}
              onChange={v => setValue('respiratory_check.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '150px' }}
              {...register('respiratory_check.input2')}
            />
          </Stack>
        </RowContent>
        <RowContent title="통증">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="ASAN.HOSPITALIZATION.PAIN"
              values={[1, 2, 3]}
              disabled={disabled}
              defaultValue={getValues('pain_ox')}
              onChange={v => setValue('pain_ox', v)}
            />
          </Stack>
          <Stack direction="row" spacing={3} sx={{ marginTop: '10px' }}>
            <Typography sx={{ fontSize: '14px', margin: 'auto 0 auto 0 ' }}>
              최초발생일
            </Typography>
            <Form.MuiTextField
              type="date"
              required={false}
              disabled={disabled}
              sx={{ width: '150px' }}
              {...register('pain_occurrence_date')}
            />
          </Stack>
        </RowContent>
        <RowContent title="마비 및 쇠약">
          <Form.MuiRadioGroup
            i18nKey="EXIST"
            values={[1, 2]}
            disabled={disabled}
            defaultValue={getValues('paralysis_ox')}
            onChange={v => setValue('paralysis_ox', v)}
          />
          <Stack direction="row" spacing={3} sx={{ marginTop: '10px' }}>
            <RowCheckboxWidth
              i18nNullKey="SPO"
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.PARALYSIS"
              values={[1, 2, 3, 4]}
              defaultValue={getValues('paralysis_check.checked')}
              onChange={v => setValue('paralysis_check.checked', v)}
            />
          </Stack>
        </RowContent>
        <RowContent title="시력 장애">
          <Form.MuiRadioGroup
            i18nKey="ASAN.HOSPITALIZATION.VISION"
            values={[1, 2, 3]}
            disabled={disabled}
            defaultValue={getValues('visual_disturbance_ox')}
            onChange={v => setValue('visual_disturbance_ox', v)}
          />
          <Stack direction="row" spacing={3} sx={{ marginTop: '10px' }}>
            <BodyConditionCheckRadio
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.VISIONLOSS"
              values={[1]}
              defaultValue={getValues('visual_disturbance_check.checked')}
              onChange={v => setValue('visual_disturbance_check.checked', v)}
            />
            <MuiRadioWidth
              i18nKey="ASAN.HOSPITALIZATION.VISIONLOSS.RADIO"
              values={[1, 2, 3]}
              disabled={disabled}
              defaultValue={getValues('visual_disturbance_radio_loss')}
              onChange={v => setValue('visual_disturbance_radio_loss', v)}
            />
            <BodyConditionCheckRadio
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.VISIONLOSS"
              values={[2]}
              defaultValue={getValues('visual_disturbance_check.checked')}
              onChange={v => setValue('visual_disturbance_check.checked', v)}
            />
            <MuiRadioWidth
              i18nKey="ASAN.HOSPITALIZATION.VISIONLOSS.RADIO"
              values={[1, 2, 3]}
              disabled={disabled}
              defaultValue={getValues(
                'visual_disturbance_radio_artificial_eyes'
              )}
              onChange={v =>
                setValue('visual_disturbance_radio_artificial_eyes', v)
              }
            />
            <BodyConditionCheckRadio
              i18nNullKey="VISIONLOSS"
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.VISIONLOSS"
              values={[3, 4]}
              defaultValue={getValues('visual_disturbance_check.checked')}
              onChange={v => setValue('visual_disturbance_check.checked', v)}
            />
          </Stack>
          <Stack direction="row" spacing={3} sx={{ marginTop: '10px' }}>
            <BodyConditionCheckRadio
              i18nNullKey="ETC"
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.RESPIRATORYETC"
              values={[0]}
              defaultValue={getValues('visual_disturbance_check.checked')}
              onChange={v => setValue('visual_disturbance_check.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '150px' }}
              {...register('visual_disturbance_input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="청력 장애">
          <Form.MuiRadioGroup
            i18nKey="ASAN.HOSPITALIZATION.HEARING"
            values={[1, 2, 3]}
            disabled={disabled}
            defaultValue={getValues('auditory_disturbance_ox')}
            onChange={v => setValue('auditory_disturbance_ox', v)}
          />
          <Stack direction="row" spacing={3} sx={{ marginTop: '10px' }}>
            <BodyConditionCheckRadio
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.HEARINGLOSS"
              values={[1]}
              defaultValue={getValues('auditory_disturbance_check.checked')}
              onChange={v => setValue('auditory_disturbance_check.checked', v)}
            />
            <MuiRadioWidth
              i18nKey="ASAN.HOSPITALIZATION.HEARING.RADIO"
              values={[1, 2, 3]}
              disabled={disabled}
              defaultValue={getValues('auditory_disturbance_radio_loss')}
              onChange={v => setValue('auditory_disturbance_radio_loss', v)}
            />
            <BodyConditionCheckRadio
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.HEARINGLOSS"
              values={[2]}
              defaultValue={getValues('auditory_disturbance_check.checked')}
              onChange={v => setValue('auditory_disturbance_check.checked', v)}
            />
            <MuiRadioWidth
              i18nKey="ASAN.HOSPITALIZATION.HEARING.RADIO"
              values={[1, 2, 3]}
              disabled={disabled}
              defaultValue={getValues('auditory_disturbance_aid')}
              onChange={v => setValue('auditory_disturbance_aid', v)}
            />
            <BodyConditionCheckRadio
              i18nNullKey="ETC"
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.HEARINGLOSS"
              values={[0]}
              defaultValue={getValues('auditory_disturbance_check.checked')}
              onChange={v => setValue('auditory_disturbance_check.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '150px' }}
              {...register('auditory_disturbance_input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="피부상태">
          <Stack direction="row" spacing={6}>
            <RowCheckboxWidth
              i18nNullKey="ETC"
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.SKIN"
              values={[1, 2, 3, 4, 5, 0]}
              defaultValue={getValues('dermal_condition_check.checked')}
              onChange={v => setValue('dermal_condition_check.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '120px' }}
              {...register('dermal_condition_check.input1')}
            />
          </Stack>
          <Stack direction="row" spacing={3} sx={{ marginTop: '10px' }}>
            <Typography
              sx={{ fontSize: '14px', margin: 'auto 0 auto 0 ', width: '30px' }}
            >
              부위
            </Typography>
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              {...register('dermal_condition_check.input2')}
            />
          </Stack>
          <Typography
            sx={{
              fontSize: '14px',
              margin: '15px 0 auto 0 ',
              color: '#2264A8',
            }}
          >
            *항목은 옴 관련 의심 증상
          </Typography>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default BodyStatus;
