import Confirm from './Confirm';
import ConfirmButton from './ConfirmButton';
import SurveyForm from './SurveyForm';

const MuiDialog = {
  Confirm,
  ConfirmButton,
  SurveyForm,
};

export default MuiDialog;
