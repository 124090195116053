import { Fragment } from 'react';
import { Stack, Typography } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentSub';
import SectionTitle from '../components/SectionTitle';
import RowCheckboxGroup from './components/RowCheckboxGroup';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const DiseaseHistory = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="투약력" />
      <RowContainer xs={12}>
        <RowContent title="최근 투약 상태">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              i18nKey="EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('dosage_condition_check')}
              onChange={v => setValue('dosage_condition_check', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              sx={{ width: '180px' }}
              placeholder="직접 입력"
              {...register('dosage_condition_input')}
            />
          </Stack>
        </RowContent>
      </RowContainer>

      <SectionTitle title="과민 반응 정보" />
      <RowContainer xs={12}>
        <RowContent title="약물">
          <Form.MuiRadioGroup
            i18nKey="ASAN.HOSPITALIZATION.MADICATION"
            values={[1, 2, 0]}
            disabled={disabled}
            defaultValue={getValues('hypersensitivity_medicine')}
            onChange={v => setValue('hypersensitivity_medicine', v)}
          />
        </RowContent>
        <RowContent title="조영제">
          <Form.MuiRadioGroup
            i18nKey="ASAN.HOSPITALIZATION.MADICATION"
            values={[1, 2, 0]}
            disabled={disabled}
            defaultValue={getValues('hypersensitivity_contrast_medium')}
            onChange={v => setValue('hypersensitivity_contrast_medium', v)}
          />
        </RowContent>
        <RowContent title="약물,조영제 외 과민반응">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              i18nKey="ASAN.HOSPITALIZATION.MADICATION"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('hypersensitivity_etc_ox')}
              onChange={v => setValue('hypersensitivity_etc_ox', v)}
            />
            <Stack direction="row" spacing={1}>
              <RowCheckboxGroup
                i18nNullKey="ETC"
                disabled={disabled}
                i18nKey="ASAN.HOSPITALIZATION.MADICATIONETC"
                values={[1]}
                defaultValue={getValues('hypersensitivity.checked')}
                onChange={v => setValue('hypersensitivity.checked', v)}
              />
              <Form.MuiTextField
                required={false}
                disabled={disabled}
                placeholder="직접 입력"
                sx={{ width: '150px' }}
                {...register('hypersensitivity.input1')}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
            <Typography sx={{ width: '197px' }}></Typography>
            <Stack direction="row" spacing={1}>
              <RowCheckboxGroup
                i18nNullKey="ETC"
                disabled={disabled}
                i18nKey="ASAN.HOSPITALIZATION.MADICATIONETC"
                values={[0]}
                defaultValue={getValues('hypersensitivity.checked')}
                onChange={v => setValue('hypersensitivity.checked', v)}
              />
              <Form.MuiTextField
                required={false}
                disabled={disabled}
                placeholder="직접 입력"
                sx={{ width: '185px', paddingLeft: '35px' }}
                {...register('hypersensitivity.input2')}
              />
            </Stack>
          </Stack>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default DiseaseHistory;
