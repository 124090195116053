import { Fragment } from 'react';
import { Stack, TextField, Typography } from '@mui/material';

import Form from 'components/Form';

import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentSub';
import SectionTitle from '../components/SectionTitle';
import MuiRadioEmotional from './components/MuiRadioEmotional';
import MuiRadioEmotionalTalk from './components/MuiRadioEmotionalTalk';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const Emotional = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="의식 및 정서 상태" />

      <RowContainer xs={12}>
        <RowContent title="의식상태">
          <MuiRadioEmotional
            i18nKey="ASAN.HOSPITALIZATION.EMOTIONAL"
            values={[1, 2, 3, 4, 5]}
            disabled={disabled}
            defaultValue={getValues('mental_check')}
            onChange={v => setValue('mental_check', v)}
          />
        </RowContent>
        <RowContent title="지남력">
          <Stack direction="row" spacing={3}>
            <Typography sx={{ margin: 'auto 0 auto 0', fontSize: '14px' }}>
              시간 (
            </Typography>
            <Form.MuiRadioGroup
              i18nKey="ASAN.HOSPITALIZATION.EMOTIONALORIENTAION"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('orientation_check_time')}
              onChange={v => setValue('orientation_check_time', v)}
            />

            <Typography
              sx={{
                fontSize: '14px',
                paddingTop: '8px',
                paddingLeft: '30px',
              }}
            >
              장소 (
            </Typography>
            <Form.MuiRadioGroup
              i18nKey="ASAN.HOSPITALIZATION.EMOTIONALORIENTAION"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('orientation_check_place')}
              onChange={v => setValue('orientation_check_place', v)}
            />

            <Typography
              sx={{
                fontSize: '14px',
                paddingTop: '8px',
                paddingLeft: '30px',
              }}
            >
              사람 (
            </Typography>
            <Form.MuiRadioGroup
              i18nKey="ASAN.HOSPITALIZATION.EMOTIONALORIENTAION"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('orientation_check_human')}
              onChange={v => setValue('orientation_check_human', v)}
            />
            <MuiRadioEmotional
              i18nKey="ASAN.HOSPITALIZATION.EMOTIONALORIENTAION"
              values={[0]}
              disabled={disabled}
              defaultValue={getValues('orientation_check_etc')}
              onChange={v => setValue('orientation_check_etc', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '150px' }}
              {...register('orientation_input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="의사 소통">
          <Stack direction="row" spacing={3}>
            <MuiRadioEmotionalTalk
              i18nKey="ASAN.HOSPITALIZATION.EMOTIONALTALK"
              values={[1, 2, 3, 4, 0]}
              disabled={disabled}
              defaultValue={getValues('communication_check')}
              onChange={v => setValue('communication_check', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '150px' }}
              {...register('communication_input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="정서 상태">
          <Stack direction="row" spacing={3}>
            <MuiRadioEmotional
              i18nKey="ASAN.HOSPITALIZATION.EMOTIONALJEONG"
              values={[1, 2, 3, 4, 5, 6, 0]}
              disabled={disabled}
              defaultValue={getValues('emotion_check')}
              onChange={v => setValue('emotion_check', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '150px' }}
              {...register('emotion_input')}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default Emotional;
