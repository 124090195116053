import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import {
  SurveyDialogProps,
  THospitalizationDefaultValues,
} from 'routes/Main/Survey/type';
import MuiDialog from 'components/MuiDialog';
import { findKeyValueToStr } from 'utils/convert';
import { createHospitalization } from 'apis/survey';
import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';

import DefaultInfo from './DefaultInfo';
import SocialStatus from './SocialStatus';
import BodyStatus from './BodyStatus';
import Habit from './Habit';
import OutHospitalPlan from './OutHospitalPlan';
import DiseaseHistory from './DiseaseHistory';
import MedicationHistory from './MedicationHistory';
import BadyCondition from './BodyCondition';
import BMI from './BMI';
import Emotional from './Emotional';

const Hospitalization = (
  props: SurveyDialogProps<THospitalizationDefaultValues>
) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();

  const { handleSubmit, register, getValues, setValue, watch } = useForm({
    defaultValues,
  });

  const onSubmit = (data: THospitalizationDefaultValues) => {
    const {
      outbreak_date,
      outbreak_time,
      info_offer_check,
      info_offer_input,
      address,
      contacts_01_name,
      contacts_01_relationship,
      contacts_02_name,
      contacts_02_relationship,

      family_history_cancer_check,
      family_history_cancer_input,
      family_history_etc_check,
      family_history_etc_input,
      relations,

      marriage_check,
      marriage_input,
      occupation,
      religion_check,
      religion_input,
      available_language,
      cultural_specialty,

      admission_path_check,
      admission_path_input,
      admission_way_check,
      admission_way_input,
      main_symptom,
      occurrence_date,
      admission_motive,
      hospital_history_check,
      hospital_history_input,
      abroad_history_input,
      abroad_history_check,

      history_ox,
      history,
      admission_history_check,
      admission_history_input,
      operation_history_check,
      operation_history_input,

      dosage_condition_check,
      dosage_condition_input,

      hypersensitivity_medicine,
      hypersensitivity_contrast_medium,
      hypersensitivity_etc_ox,
      hypersensitivity,

      sbp,
      dbp,
      pr,
      rr,
      bt,
      sp02,

      height,
      weight,
      measurement_method,

      respiratory_ox,
      respiratory_check,

      pain_ox,
      pain_occurrence_date,

      paralysis_ox,
      paralysis_check,

      visual_disturbance_ox,
      visual_disturbance_check,
      visual_disturbance_radio_loss,
      visual_disturbance_radio_artificial_eyes,

      auditory_disturbance_ox,
      auditory_disturbance_check,
      auditory_disturbance_radio_loss,
      auditory_disturbance_aid,

      dermal_condition_check,

      nutritional_danger_bmi_input,
      nutritional_danger_bmi_check,
      nutritional_danger_check_monthly,
      nutritional_danger_check_weekly,
      nutritional_danger_check_icu,
      nutritional_danger_check_result,

      mental_check,
      orientation_check_time,
      orientation_check_place,
      orientation_check_human,
      orientation_check_etc,
      orientation_input,
      communication_check,
      communication_input,
      emotion_check,
      emotion_input,

      habit_stool_times_per,
      habit_stool_day,
      habit_stool_pattern_check,

      habit_urine_times_per,
      habit_urine_day,
      habit_urine_pattern_check,
      habit_urine_pattern_input,
      habit_urine_color_check,
      habit_urine_amount_input,
      habit_urine_odor,
      habit_urine_check,

      habit_sleep_hours,
      habit_sleep_check,
      habit_sleep_input,

      amis_educational_demand,

      discharge_plan_ox,
      discharge_plan_teacher_check,
      discharge_plan_teacher_input,
    } = data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      amis_hospitalization_survey: {
        patientName: patientInfo.name,
        age: patientInfo.age,
        gender: patientInfo.gender,
        department: patientInfo.department,
        userName: nurseName,
        mainDoctor: patientInfo.main_doctor,
        offer: data.offer,

        outbreak_date,
        outbreak_time,
        info_offer_check,
        info_offer_input,
        address,
        contacts_01_name,
        contacts_01_relationship,
        contacts_02_name,
        contacts_02_relationship,

        family_history_cancer_check,
        family_history_cancer_input,
        family_history_etc_check,
        family_history_etc_input,
        relations: findKeyValueToStr(relations),

        marriage_check,
        marriage_input,
        occupation,
        religion_check,
        religion_input,
        available_language: findKeyValueToStr(available_language),
        cultural_specialty,

        admission_path_check,
        admission_path_input,
        admission_way_check,
        admission_way_input,
        main_symptom,
        occurrence_date,
        admission_motive,
        hospital_history_check,
        hospital_history_input,
        abroad_history_input,
        abroad_history_check,

        history_ox,
        history: findKeyValueToStr(history),
        admission_history_check,
        admission_history_input,
        operation_history_check,
        operation_history_input,

        dosage_condition_check,
        dosage_condition_input,

        hypersensitivity_medicine,
        hypersensitivity_contrast_medium,
        hypersensitivity_etc_ox,
        hypersensitivity: findKeyValueToStr(hypersensitivity),

        sbp,
        dbp,
        pr,
        rr,
        bt,
        sp02,

        height,
        weight,
        measurement_method,

        respiratory_ox,
        respiratory_check: findKeyValueToStr(respiratory_check),

        pain_ox,
        pain_occurrence_date,

        paralysis_ox,
        paralysis_check: findKeyValueToStr(paralysis_check),

        visual_disturbance_ox,
        visual_disturbance_check: findKeyValueToStr(visual_disturbance_check),
        visual_disturbance_radio_loss,
        visual_disturbance_radio_artificial_eyes,

        auditory_disturbance_ox,
        auditory_disturbance_check: findKeyValueToStr(
          auditory_disturbance_check
        ),
        auditory_disturbance_radio_loss,
        auditory_disturbance_aid,

        dermal_condition_check: findKeyValueToStr(dermal_condition_check),

        nutritional_danger_bmi_input,
        nutritional_danger_bmi_check,
        nutritional_danger_check_monthly,
        nutritional_danger_check_weekly,
        nutritional_danger_check_icu,
        nutritional_danger_check_result,

        mental_check,
        orientation_check_time,
        orientation_check_place,
        orientation_check_human,
        orientation_check_etc,
        orientation_input,
        communication_check,
        communication_input,
        emotion_check,
        emotion_input,

        habit_stool_times_per,
        habit_stool_day,
        habit_stool_pattern_check: findKeyValueToStr(habit_stool_pattern_check),

        habit_urine_times_per,
        habit_urine_day,
        habit_urine_pattern_check,
        habit_urine_pattern_input,
        habit_urine_color_check: findKeyValueToStr(habit_urine_color_check),
        habit_urine_amount_input,
        habit_urine_odor,
        habit_urine_check: findKeyValueToStr(habit_urine_check),

        habit_sleep_hours,
        habit_sleep_check,
        habit_sleep_input,

        amis_educational_demand: findKeyValueToStr(amis_educational_demand),

        discharge_plan_ox,
        discharge_plan_teacher_check,
        discharge_plan_teacher_input,
      },
    };

    createHospitalization(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess('입원기록지 저장에 성공하였습니다.');
      })
      .catch(e => onFail('입원기록지 저장에 실패하였습니다.', e));
  };

  const formProps = {
    disabled,
    watch,
    register,
    getValues,
    setValue,
    onSuccess,
    onRequired,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Grid
        container
        wrap="wrap"
        rowSpacing={5}
        columnSpacing={2}
        sx={{ py: 5, px: 1 }}
      >
        <Typography
          sx={{
            margin: '40px auto 0px auto',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          입원간호 기록지
        </Typography>
        <DefaultInfo {...formProps} />
        <SocialStatus {...formProps} />
        <BodyStatus {...formProps} />
        <DiseaseHistory {...formProps} />
        <MedicationHistory {...formProps} />
        <BadyCondition {...formProps} />
        <BMI {...formProps} />
        <Emotional {...formProps} />
        <Habit {...formProps} gender={patientInfo.gender} />
        <OutHospitalPlan {...formProps} />
      </Grid>
    </MuiDialog.SurveyForm>
  );
};

export default Hospitalization;
