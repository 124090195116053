import { Fragment } from 'react';
import { Stack, TextField } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentSub';
import SectionTitle from '../components/SectionTitle';

import MuiRadioGroupSub from './components/MuiRadioGroupSub';
import MuiRadioBodyStatus from './components/MuiRadioBodyStatus';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const BodyStatus = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="신체사정" />

      <RowContainer xs={12}>
        <RowContent title="입원 경로">
          <Stack direction="row" spacing={3}>
            <MuiRadioGroupSub
              i18nKey="ASAN.HOSPITALIZATION.PATH"
              values={[1, 2, 3, 4, 0]}
              disabled={disabled}
              defaultValue={getValues('admission_path_check')}
              onChange={v => setValue('admission_path_check', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '120px' }}
              {...register('admission_path_input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="입원 방법">
          <Stack direction="row" spacing={3}>
            <MuiRadioGroupSub
              i18nKey="ASAN.HOSPITALIZATION.METHOD"
              values={[1, 2, 3, 4, 0]}
              disabled={disabled}
              defaultValue={getValues('admission_way_check')}
              onChange={v => setValue('admission_way_check', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '120px' }}
              {...register('admission_way_input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="주증상">
          <Stack direction="row" spacing={3}>
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              {...register('main_symptom')}
            />
          </Stack>
        </RowContent>
        <RowContent title="발생 일자">
          <Stack direction="row" spacing={3}>
            <Form.MuiTextField
              type="date"
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '180px' }}
              {...register('occurrence_date')}
            />
          </Stack>
        </RowContent>
        <RowContent title="입원 동기">
          <Form.MuiTextField
            required={false}
            disabled={disabled}
            multiline
            minRows={6}
            placeholder="직접 입력"
            {...register('admission_motive')}
          />
        </RowContent>
        <RowContent title="타원 경유력">
          <Stack direction="row" spacing={6}>
            <MuiRadioBodyStatus
              i18nKey="ASAN.HOSPITALIZATION.THROUGH"
              values={[1, 2, 3]}
              disabled={disabled}
              defaultValue={getValues('hospital_history_check')}
              onChange={v => setValue('hospital_history_check', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '120px' }}
              {...register('hospital_history_input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="해외 경유력">
          <Stack direction="row" spacing={6}>
            <MuiRadioBodyStatus
              i18nKey="ASAN.HOSPITALIZATION.THROUGH"
              values={[1, 2, 4]}
              disabled={disabled}
              defaultValue={getValues('abroad_history_input')}
              onChange={v => setValue('abroad_history_input', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '120px', marginLeft: '20px' }}
              {...register('abroad_history_check')}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default BodyStatus;
