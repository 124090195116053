import { Fragment } from 'react';
import { Stack } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentSub';
import SectionTitle from '../components/SectionTitle';
import RowCheckboxGroup from './components/RowCheckboxGroup';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const DiseaseHistory = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="과거력" />

      <RowContainer xs={12}>
        <RowContent title="과거력">
          <Form.MuiRadioGroup
            i18nKey="EXIST"
            values={[1, 2]}
            disabled={disabled}
            defaultValue={getValues('history_ox')}
            onChange={v => setValue('history_ox', v)}
          />
          <Stack direction="row" spacing={1}>
            <RowCheckboxGroup
              i18nNullKey="ETC"
              disabled={disabled}
              i18nKey="ASAN.HOSPITALIZATION.DISEASE"
              values={[1, 2, 3, 4, 0]}
              defaultValue={getValues('history.history.checked')}
              onChange={v => setValue('history.history.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '150px' }}
              {...register('history.history.input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="입원력">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              i18nKey="EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('admission_history_check')}
              onChange={v => setValue('admission_history_check', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              {...register('admission_history_input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="수술 병력">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              i18nKey="EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('operation_history_check')}
              onChange={v => setValue('operation_history_check', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              {...register('operation_history_input')}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default DiseaseHistory;
