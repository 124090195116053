export const initialToggleObj = {
    patient_evaluation: false,
    pain: false,
    mental_health: false,
    special: false,
    agreement: false,
}

export const initialSettingsToggleObj = {
    medical_information: false,
}